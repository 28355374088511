@import 'scss/variables';
@import 'scss/spacing';

.calendar__icon{
    pointer-events: none;
}

.calendar{
  padding-right: 60px;
}

.date-container:hover input{
  cursor: pointer;
}

p-calendar span {
  width: 100%;
}
