
$fa-font-path : '~font-awesome/fonts';
$headerHeight: 80px;

$grid-breakpoints: (
  zero: 0,
  xs: 320px,
  sm: 760px,
  md: 980px,
  lg: 1440px
);

$container-max-widths: (
  sm: 420px,
  md: 720px,
  lg: 960px
);

/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
  @if $point == mobile {
      @media (max-width: map-get($grid-breakpoints, sm ))  { @content ; }
  }
  @else if $point == desktop {
    @media (min-width: map-get($grid-breakpoints, md ))  { @content ; }
  }
  @else if $point == desktopDown {
    @media (max-width: map-get($grid-breakpoints, md )-1)  { @content ; }
  }
  @else if $point == tablet {
    @media (min-width: map-get($grid-breakpoints, sm )) { @content ; }
  }
  @else if $point == wide {
    @media (min-width: 1440px) { @content ; }
  }
}

$font--default: "Suisse Light", Arial, sans-serif;
$font--medium: "Suisse Medium", Arial, sans-serif;
$easing-cubic: cubic-bezier(0.5, 0.02, 0.5, 1);
