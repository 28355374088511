import { Component, OnInit } from '@angular/core';
import { Utilities } from '../../../utils/utilities';

@Component({
  selector: 'bdo-poll-done',
  templateUrl: './poll-done.component.html',
  styleUrls: ['./poll-done.component.scss']
})
export class PollDoneComponent {

}
