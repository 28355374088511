import { Component, OnInit } from '@angular/core';
import { TenantService } from '../../services/tenant.service';
import { CustomerStoreService } from '../../services/customer-store.service';
import { DropdownItem } from '../../models/dropdownItem';
import { Configuration, DocumentData } from '../../../../assets/js/com/ts_api_client';
import { BdoApiService } from '../../services/bdo-api.service';
import { DOCUMENTGROUP } from '../../enums/documentGroup';
import { DatePipe } from '@angular/common';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../services/toast.service';
import { forkJoin, Observable, of } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { Utilities } from '../../../shared/utils/utilities';
import { PollService } from '../../services/poll.service';

enum DOCUMENT_DOWNLOAD_STATE {
  IN_PROGRESS,
  ERROR,
  SUCCESS,
  IDLE
}

interface DocumentStateData {
  state: DOCUMENT_DOWNLOAD_STATE;
  percentage: number;
  data: DocumentData;
}
@Component({
  selector: 'bdo-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  public error: string;
  public DocumentDownloadState = DOCUMENT_DOWNLOAD_STATE;
  public isLoading: boolean = false;
  public customerNumber: string;
  public showPoll: boolean = false;
  public dropdownItems: Array <DropdownItem> = [];
  public documentData: Array <DocumentStateData> = [];
  public totalRecords: number = 0;
  public DocumentGroup = DOCUMENTGROUP;

  constructor(
    public tenantService: TenantService,
    public datePipe: DatePipe,
    public pollService: PollService,
    public toastService: ToastService,
    public configuration: Configuration,
    private apiService: BdoApiService,
    private trackingService: TrackingService,
    public customerStore: CustomerStoreService,
    private translateService: TranslateService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.DOCUMENTS, TRACKING.ACTION.ENTER);

    this.customerNumber = this.customerStore.getAccountId();

    this.totalRecords = this.getRecordsToAdd();
    this.dropdownItems = [new DropdownItem('ALL', this.DocumentGroup.ALL)];

    this.isLoading = true;
    this.initDocuments();
  }

  initDocuments() {
    forkJoin([this.apiService.getDocumentGroups(), this.getDocuments()]).pipe(
    ).subscribe({
      next: ([groups,]) => {
        groups.forEach(doc => {
          this.dropdownItems.push({ value: doc, label: this.DocumentGroup[doc] });
        });
      },
      error: () => {
        this.setError(this.translateService.instant('general.error.error'));
      }
    });

  }

  setError(error: string) {
    this.error = error;
  }

  updateResults(group: string) {
    this.getDocuments(group).subscribe();
    this.totalRecords = this.getRecordsToAdd();
  }

  /** Depending on Width returns the amount of records that should be added */
  getRecordsToAdd(recordsToAddDesktop = 5, recordsToAddMobile = 3) {
    return document.documentElement.clientWidth > 760 ? recordsToAddDesktop : recordsToAddMobile;
  }

  addRecords(data: Array<any>) {
    this.trackingService.postTracking(TRACKING.LOCATION.DOCUMENTS, TRACKING.ACTION.MORE);

    const recordsToAdd = this.getRecordsToAdd();
    if (this.totalRecords + recordsToAdd > data.length) {
      this.totalRecords = data.length;
    } else {
      this.totalRecords += recordsToAdd;
    }
  }

  createDocUrl(docId: string, token) {
    return `${this.configuration.basePath}/download/${encodeURIComponent(this.customerNumber)}/docpdf/${encodeURIComponent(docId)}?t=${token}`;
  }

  /**
   * Filename shall be: YYYY-MM-DD_DocTitle
   * @param docId
   */
  generateFileName(docId: string): string {
    const docData = this.documentData.find(documentData => documentData.data.id === docId);
    const date = (this.datePipe.transform(docData.data.date, 'yyyy-MM-dd'));
    return date + '_' + docData.data.title + '.pdf';
  }

  handleClickOnDocument($event: any, documentStateData: DocumentStateData) {

    this.trackingService.postTracking(TRACKING.LOCATION.DOCUMENTS, TRACKING.ACTION.DOWNLOAD);
    this.updateDocumentState(documentStateData, DOCUMENT_DOWNLOAD_STATE.IN_PROGRESS);
    this.apiService.getTempToken().pipe(
      switchMap((t) => {
          return this.http.request('GET', this.createDocUrl(documentStateData.data.id, t.token),
            {
              observe: 'events',
              reportProgress: true,
              responseType: 'blob'
            }).pipe(
            map(event => this.getEventMessage(event as HttpEvent<any>, documentStateData)),
            filter(f => f != null)
          );
        }
      )
  ).subscribe({
      next: () => {
        this.updateDocumentState(documentStateData, DOCUMENT_DOWNLOAD_STATE.SUCCESS);
        this.trackingService.postSimpleTracking('DocumentDownload', TRACKING.ACTION.SUCCESS);
        this.setError(null);
        this.pollService.activatePoll({ pollId: 3, triggerPoint: 'Dokumentendownload' });
      },
      error: (error: unknown) => {
        this.updateDocumentState(documentStateData, DOCUMENT_DOWNLOAD_STATE.ERROR);
        this.trackingService.postSimpleTracking('DocumentDownload', TRACKING.ACTION.FAILURE);
        this.setError(this.translateService.instant('general.error.error'));
        this.pollService.activatePoll({ pollId: 3, triggerPoint: 'Dokumentendownload' });
      }
    });
  }

  private updateDocumentState(documentStateData: DocumentStateData, state: DOCUMENT_DOWNLOAD_STATE) {
    documentStateData.state = state;
    this.documentData = this.documentData.map((item) => {
      return item.data.id !== documentStateData.data.id ? item : {
        ...documentStateData
      };
    });
  }

  private getEventMessage(event: HttpEvent<any>, documentStateData: DocumentStateData): ArrayBuffer {
      switch (event.type) {

        case HttpEventType.DownloadProgress:
          // download in progress compute and show the % done:
          const percentDone = Math.round(100 * event.loaded / event.total);
          this.documentData = this.documentData.map((item) => {
            return item.data.id !== documentStateData.data.id ? item : {
              ...documentStateData,
              percentage: percentDone
            };
          });
          return null;

        case HttpEventType.Response:
          const anchorTag = document.createElement('a');
          const contentDisposition = event.headers.get('content-disposition');
          let filename = this.generateFileName(documentStateData.data.id);
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
          anchorTag.setAttribute('href', window.URL.createObjectURL(event.body));
          anchorTag.setAttribute('download', filename);
          anchorTag.setAttribute('target', '_blank');

          const clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          });
          document.body.appendChild(anchorTag);
          setTimeout(() => {
            anchorTag.dispatchEvent(clickEvent);
          }, 500);
          document.body.removeChild(anchorTag);
          return event.body;

        default:
          return null;
      }
  }
  private getDocuments(docGroup: string = ''): Observable<Array <DocumentStateData>> {
    this.isLoading = true;
    if (docGroup === 'ALL') {
      docGroup = '';
    }
   return this.apiService.getDocuments(docGroup).pipe(
      map(res => {
        if (!res) {
          return [];
        }
        return res.map((documentDataItem) => ({
          percentage: 0,
          data: documentDataItem,
          state: DOCUMENT_DOWNLOAD_STATE.IDLE
        }));
      }),
      catchError(error => {
        this.setError(this.translateService.instant('general.error.error'));
        return of([]);
      }),
     tap((result) => {
       this.documentData = result;
       this.isLoading = false;
     })
    );
  }

}
