import { computed, effect, Injectable, signal, untracked, WritableSignal } from '@angular/core';
import { Utilities } from '../../shared/utils/utilities';
import { BdoApiService } from './bdo-api.service';
import { Poll } from '../../../assets/js/com/ts_api_client';
import { ToastService } from './toast.service';

export interface PollConfig {
  pollId: number;
  triggerPoint: PollTriggerPoint;
  delay?: number;
}

export type PollTriggerPoint = 'Abschlag_anpassen' | 'Dokumentendownload' | 'Zaehlerstand';

@Injectable({
  providedIn: 'root'
})
export class PollService {
  public readonly showPollToast = computed(() => this.showPoll() && !this.toastService.currentToast());
  public readonly currentPollConfig: WritableSignal<PollConfig | null> = signal<PollConfig | null>(null);
  public readonly currentPoll = signal<Poll | null>(null);
  private readonly showPoll: WritableSignal<boolean> = signal<boolean>(false);

  private readonly defaultDelay = 3000; // 3 seconds

  constructor(
    private apiService: BdoApiService,
    private toastService: ToastService
  ) {
    effect(() => {
      const config = this.currentPollConfig();
      if (config) {
        untracked(() => {
          this.apiService.getPoll(config.pollId).subscribe((res) => this.currentPoll.set(res));
        });
      }
    });
  }

  public createPollShownCookie(): void {
    Utilities.createCookie('poll_shown', '1', 90);
  }

  /**
   * Checks if the poll can be shown and activates it after a delay if possible
   * @param config Configuration for the poll
   * @returns void
   */
  public activatePoll(config: PollConfig): void {
    if (!this.canShowPoll()) {
      return;
    }

    this.currentPollConfig.set(config);
    const delay = config.delay ?? this.defaultDelay;

    setTimeout(() => {
      this.createPollShownCookie();
      this.showPoll.set(true);
    }, delay);
  }

  /**
   * Closes the current poll and resets the configuration
   */
  public closePoll(): void {
    this.showPoll.set(false);
    this.currentPollConfig.set(null);
  }

  /**
   * Checks if the poll can be shown based on session and cookie state
   * @returns boolean indicating if the poll can be shown
   */
  private canShowPoll(): boolean {
    return !Utilities.getCookie('poll_shown');
  }
}
