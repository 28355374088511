<div class="bdo-container re-mb-30 lg:re-mb-60">
	<h3>
		{{ 'documents.title' | translate }}
	</h3>
</div>

<div class="bdo-container--single">
  <div class=" re-copytext re-mb-30 md:re-mb-45">
		{{ 'documents.description' | translate }}
  </div>

  <div class="re-mb-15 re-bdo-tile">
    <bdo-accountid-with-address></bdo-accountid-with-address>
  </div>

  <!-- show errors on all backend errors -->
  <bdo-info-box class="bdo-container--single" *ngIf="error">{{ error }}</bdo-info-box>

	<form class="bdo-form-container re-mb-10">
		<fieldset class="re-mb-0">
			<div class="bdo-form-item-container">
				<bdo-dropdown
					[data]="dropdownItems"
					[selected]="dropdownItems[0].value"
          [labelText]="'documents.selectionName' | translate"
					(itemSelected)="updateResults($event)"
				></bdo-dropdown>
			</div>
		</fieldset>
	</form>
	<div class="bdo-loading__wrapper re-mb-60 md:re-mb-90" [class.bdo-loading__wrapper--height]="isLoading">
		<bdo-loading *ngIf="isLoading"></bdo-loading>
		<div class="bdo-table tablet-up-only">

			<table class="table--desktop">
				<thead>
					<tr>
						<th>{{ 'documents.result.date' | translate }}</th>
						<th>{{ 'documents.result.type' | translate }}</th>
						<th class="re-text-right">{{ 'documents.result.downloadHeader' | translate }}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let doc of documentData | slice: 0:totalRecords">
						<td class="bdo-font--monospace">{{ doc?.data?.date | date }}</td>
						<td>
							{{ doc?.data?.title }}
						</td>
						<td>

							<button class="document-icon icon__download icon__download--desktop hover-container re-ml-auto re-text-right re-cursor-pointer re-block"
                [title]="'documents.downloadButtonLabel' | translate: {type: doc?.data?.title, date: (doc?.data?.date | date)}"
                (click)="handleClickOnDocument($event, doc)">
                <div *ngIf="doc.state === DocumentDownloadState.IN_PROGRESS">
                  <div class="in-progress"></div>
                </div>
                <svg-icon src="{{ 'documents.img.download' | translate }}"
                          *ngIf="doc.state === DocumentDownloadState.IDLE || doc.state === DocumentDownloadState.SUCCESS || doc.state === DocumentDownloadState.ERROR">
                </svg-icon>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="mobile-only re-mb-10">
      <div *ngFor="let doc of documentData | slice: 0:totalRecords" class="re-mb-5">
        <div class="bdo-tile space-line re-mb-2">
          <div class="bold">{{ 'documents.result.date' | translate }}</div>
          <div class="re-mb-15">{{ doc?.data?.date | date }}</div>
          <div class="bold">{{ 'documents.result.type' | translate }}</div>
          <div>{{ doc?.data?.title }}</div>
        </div>
        <div class="bdo-tile">
          <button class="document-icon re-flex-row re-flex bold hover-container re-cursor-pointer" (click)="handleClickOnDocument($event, doc)">
            {{ 'documents.result.download' | translate }}
            <div *ngIf="doc.state === DocumentDownloadState.IN_PROGRESS">
              <div class="in-progress"></div>
            </div>
            <svg-icon src="{{ 'documents.img.download' | translate }}"
                      *ngIf="doc.state === DocumentDownloadState.IDLE || doc.state === DocumentDownloadState.SUCCESS || doc.state === DocumentDownloadState.ERROR">
              >
            </svg-icon>
          </button>
        </div>
      </div>
		</div>

		<bdo-more-button *ngIf="documentData?.length > totalRecords"
      class="re-mt-30"
			[label]="'general.action.showMore' | translate"
			(moreEvent)="addRecords(documentData)"
		></bdo-more-button>

		<bdo-no-data
      *ngIf="!isLoading && (!documentData || documentData.length === 0)"
			[text]="'documents.noData' | translate">
    </bdo-no-data>

	</div>
</div>

<!-- FAQs -->
<bdo-faqs
  [keyName]="'documents.faqs'"
  [params]="{
              contactLink: tenantService.getCurrentTenantData().hrefContact,
              billingEditLink: 'abschlag/' + customerStore.getAccountId(),
              billExplanationLink: tenantService.getCurrentTenantData().hrefBillExplanation
            }"
></bdo-faqs>
<bdo-poll-frame>
  <bdo-customer-satisfaction-poll />
</bdo-poll-frame>
