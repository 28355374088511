import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { DatadogConfig, Environment, LinksMarketingPixelConfig, sfMap, URLs } from './environments/environment';
import { STAGE } from './app/bdo/enums/stage.enum';
import { AwsAuthConfig } from './app/bdo/models/AwsAuthConfig';

const init = async () => {
  const configuration = (await fetch('config/config.json').then((res) => res.json()));
  // Load configuration that is external from build
  Environment.version = {
    branch: configuration.branch,
    revision: configuration.revision
  };
  Environment.stage = configuration.stage;
  Environment.tenant = configuration.tenant;
  Environment.kameleoon = configuration.kameleoon;
  Environment.baseHref = configuration.baseHref;
  Environment.apiTags = configuration.apiTags;
  Environment.branch = configuration.branch;
  DatadogConfig.applicationId = configuration?.datadogConfig.applicationId;
  DatadogConfig.clientToken = configuration?.datadogConfig.clientToken;
  DatadogConfig.appVersion = configuration?.datadogConfig.appVersion;
  LinksMarketingPixelConfig.tellja = configuration?.LinksMarketingPixelConfig?.tellja;
  LinksMarketingPixelConfig.affiliate = configuration?.LinksMarketingPixelConfig?.affiliate;
  Environment.awsAuthConfig = configuration.awsAuthConfig as AwsAuthConfig;
  URLs.backend = configuration.urls.backend;
  URLs.sfLoginUrl.set(sfMap[Environment.stage] + '/login');
  URLs.sfRegisterUrl.set(sfMap[Environment.stage] + '/Register');

  if (Environment.stage === STAGE.Production || Environment.stage === STAGE.Staging) {
    enableProdMode();
  }
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
};


init();
