import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { PollService } from '../../../bdo/services/poll.service';

@Component({
  selector: 'bdo-poll-frame',
  templateUrl: './poll-frame.component.html',
  styleUrl: './poll-frame.component.scss'
})
export class PollFrameComponent{
  constructor(public readonly pollService: PollService) {
  }

  onClose() {
    this.pollService.closePoll();
  }
}
