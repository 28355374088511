import { Component, computed, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { PollAnswer } from '../../../../assets/js/com/ts_api_client';
import { BdoApiService } from '../../../bdo/services/bdo-api.service';
import { CustomerStoreService } from '../../../bdo/services/customer-store.service';
import { Utilities } from '../../utils/utilities';
import { PollService } from '../../../bdo/services/poll.service';

@Component({
  selector: 'bdo-customer-satisfaction-poll',
  templateUrl: './customer-satisfaction-poll.component.html',
  styleUrls: ['./customer-satisfaction-poll.component.scss']
})
export class CustomerSatisfactionPollComponent {
  public poll = this.pollService.currentPoll;
  public maxQuestions = computed(() => this.poll()?.questions?.length);
  public activeQuestion = 0;

  // checks if the poll can be shown everytime the config changes
  public showPoll: Signal<boolean> = computed(() => {
    this.pollService.currentPollConfig();
    return Boolean(!Utilities.getCookie('poll_shown'));
  });

  constructor(
    private pollService: PollService,
    private apiService: BdoApiService,
    private router: Router,
    private customerStore: CustomerStoreService
  ) {
  }

  onQuestionComplete(answer: string) {
    const answerObject: PollAnswer = {
      pollId: this.poll().pollId,
      accountId: this.customerStore.getAccountId() || 'empty',
      path: this.router.url,
      questionNo: this.poll()?.questions[this.activeQuestion].questionNo,
      triggerPoint: this.pollService.currentPollConfig().triggerPoint,
      answer: answer
    };
    this.apiService.postPollAnswer(answerObject).subscribe();

    if (this.activeQuestion === this.maxQuestions()) {
      this.activeQuestion = -1;
    } else {
      this.activeQuestion ++;
    }
  }

}
